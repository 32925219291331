import { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import InfraAvatar from './InfraAvatar';
import React, { useContext } from 'react';
import HtmlContentWidget from './HtmlContentWidget';
import TypingMessage from './TypingMessage';
import ChatHeader from './ChatHeader';
import HtmlContentWelcomeWidget from './HtmlContentWelcomeWidget';
import HtmlContentDefaultMessage from './HtmlContentDefaultMessage';




//https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/

const botName = 'Hedgy';
const config = {

  initialMessages: [createChatBotMessage({ message: `Hi, I'm  ${botName}. How can i help you?`, type: 'welcome', id: '000' })],
  customStyles: {
   
  },
  botName: botName,
  customComponents: {
  
    header: () => <ChatHeader text={`Hi there `} />,
    botAvatar: (props) => <InfraAvatar {...props} />,
    botChatMessage: (props) => {
      if (props.message.type === 'html') {
        return <HtmlContentWidget {...props} />;                
      } else if (props.message.type === 'welcome') {
        return <HtmlContentWelcomeWidget {...props} />;
      } else if (props.message.type === 'typing' && props.message.message !== "done") {
        return <TypingMessage messages={props.message} />;}    
      else if (props.message.type !== 'typing') {
        return <HtmlContentDefaultMessage {...props} />;                
      }
      else{
        return <React.Fragment></React.Fragment>
      }

    },
  },

  widgets: [
    
  ],
};

export default config;