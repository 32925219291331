import React, { useContext } from 'react';
import './HtmlContentWidget.css';


const HtmlContentWidget = ({ message, html = null, customClass , click = null }) => {

  if (!message && html === null) {
    return (
      <div></div>
    )
  }
  return (


    html === null ?
      (
        <div className='console-ai-chat-container' dangerouslySetInnerHTML={{ __html: message.message }} />
      ) :
      (
        <div className={`console-ai-chat-container ${customClass}`}  dangerouslySetInnerHTML={{ __html: html }} onClick={click} />
      )



  );
};

export default HtmlContentWidget;
