// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
//import Avatar from '../../assets/chatButton.svg'
import Avatar from '../../assets/platform-icon.png'
import './InfraAvatar.css';



const InfraAvatar = () => {

    return (
       
            <img className='infra-copilot-avatar' src={Avatar} alt="avatar" />
       
    );
};

export default InfraAvatar;