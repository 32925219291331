import React, { useContext } from 'react';
import './HtmlContentDefaultMessage.css';



const HtmlContentDefaultMessage = ({ message }) => {

  if (!message) {
    return (
      <div></div>
    )
  }
 
  return (
    <div className="markdown-content console-ai-chat-container" dangerouslySetInnerHTML={{ __html: message }} />

  );
};

export default HtmlContentDefaultMessage;
