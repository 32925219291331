import { createContext, useState } from 'react';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    
    const [disableUserChatSendBtn, setDisableUserChatSendBtn] = useState(false)

    return (
        <DataContext.Provider value={{
            disableUserChatSendBtn, setDisableUserChatSendBtn
        }}>



            {children}
        </DataContext.Provider>
    )

}

export default DataContext;
