import logo from './logo.svg';
import './App.css';
import BotComponent from './components/bot/BotComponent';
import { DataProvider } from './context/DataContext';

function App() {
  return (
    <DataProvider>
      <div className="container">
        <header className="header">
          <h1><b>Hedgy</b>, The new generation of the AI Jet Bot</h1>
        </header>

        <main className="body">
          <BotComponent />
        </main>

        <footer className="footer">
          <p>JET Hackathon 2024</p>
        </footer>
      </div>
    </DataProvider>
  );
}

export default App;
