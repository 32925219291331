// in ActionProvider.jsx
import React, { useContext, useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import DataContext from '../../context/DataContext';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const instance = useAxios("https://infrastructure-analyzer-assistants-infra.internal.tenbis.cloud");

  const { setDisableUserChatSendBtn } = useContext(DataContext);




  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };


  const handleMessage = async (message) => {
  
    try {
      setDisableUserChatSendBtn(true)
      const messageTyping = createChatBotMessage({ message: "", type: 'typing', id: '1234' });

      setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, messageTyping]
      }));

    
      const response = await instance.post('/api/v1/Chat/chats/67555c2b5c25faa6531e0d79/messages', {
        input: message
      });

      if (messageTyping.message.id === '1234') {
         messageTyping.message.message = 'done';
      }
     
     setDisableUserChatSendBtn(false)
     let botMessage = null;
      if (response.data && response.data?.data?.chatResponse) {
        botMessage = createChatBotMessage(response.data.data.chatResponse);
      }
      else {
        botMessage = createChatBotMessage('Sorry, i dont have any information for you.');
      }
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
      return null;
      
    } catch (error) {
      console.error("Error fetching weather data", error);
      
      const errorBotMessage = createChatBotMessage('Sorry, something went wrong.');
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, errorBotMessage],
      }));

      setDisableUserChatSendBtn(false)
      return null;
    }



  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleMessage
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;