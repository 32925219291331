import './ChatHeader.css';


const ChatHeader = ({ text }) => {

    
    return (
        <div className='copilot-chat-header' >
            
            <span className='copilot-chat-header-text'>{text} <span className="mp0 nj0" aria-label="Emoji: Waving Hand" title="Waving Hand" alt="👋"></span></span>
                                              
        </div>
    );
};

export default ChatHeader;