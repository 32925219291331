import React, { useContext } from 'react';
import './HtmlContentWelcomeWidget.css';



const HtmlContentWelcomeWidget = ({ message }) => {


  if (!message) {
    return (
      <div></div>
    )
  }
 
  return (
    <div className="markdown-content console-ai-chat-container" dangerouslySetInnerHTML={{ __html: message.message }} />

  );
};

export default HtmlContentWelcomeWidget;
