import axios from 'axios';


const useAxios = (baseUrl) => {

    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCIsImtpZCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCJ9.eyJhdWQiOiJhcGk6Ly8xZmQ5ZWEwMi0yMzA1LTQ0NWItODlkOS01NDA0NTFhNTFhNGIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83NjZhNzczZC02ZjM2LTRjNzktOTdiMi0wNTk3M2UyNGY2NGYvIiwiaWF0IjoxNzMzNjU0NzI3LCJuYmYiOjE3MzM2NTQ3MjcsImV4cCI6MTczMzY1OTg4NywiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhZQUFBQVRSeGVLZVZIOTRybGFQUWgrd05HN2NKbEdHK0RFZFJ0WGpYM1dQZXhKRlpMN3FzeFhUcEo3NC85bkY0dTZzcC9HdVczNWsydlJQSzg4RzM0a201UTlBczZ6akpSVmV6WVU3aGpHaEhWUWpnPSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiJhY2M5MjBmMS0zYzI3LTQ3NGItODRhNy02NGJmOGE3MzhmMjciLCJhcHBpZGFjciI6IjAiLCJlbWFpbCI6InRhbG9AMTBiaXMuY28uaWwiLCJmYW1pbHlfbmFtZSI6Ik9ybGluc2t5IiwiZ2l2ZW5fbmFtZSI6IlRhbCIsImlwYWRkciI6ImZkZTQ6OGRiYToxMjAwOjM4NDQ6NmUxMjozYjAwOmEwNToyODA0IiwibmFtZSI6IlRhbCBPcmxpbnNreSIsIm9pZCI6Ijc3NjY2ZjRhLTQ1NDEtNDlmMy05MTVkLTNmNjFhODk1NzhhOCIsInJoIjoiMS5BWFFBUFhkcWRqWnZlVXlYc2dXWFBpVDJUd0xxMlI4RkkxdEVpZGxVQkZHbEdrdmlBUGwwQUEuIiwicm9sZXMiOlsiU2VydmljZUNhdGFsb2ctQWRtaW4iXSwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic3ViIjoiUkVieTJuQzRaalRwS3VWclZPLVJET0cwQjA5V2FMTG9yWGtsb2g5bmE5MCIsInRpZCI6Ijc2NmE3NzNkLTZmMzYtNGM3OS05N2IyLTA1OTczZTI0ZjY0ZiIsInVuaXF1ZV9uYW1lIjoidGFsb0AxMGJpcy5jby5pbCIsInVwbiI6InRhbG9AMTBiaXMuY28uaWwiLCJ1dGkiOiJPcDF3ZDE4X3ZVeXozZ0NiV0tkUkFBIiwidmVyIjoiMS4wIn0.K1xbzvxgdinu8ggpTAZIYRO7bGiwvIYUHkKJCc4SN5e4VZNkwQ9JeLzCLSo_LrlR1vC5inCz9ko8OQ1WmGo6IABcC_3rgNI_1jiOfWFc7LpHam_wmM9v1Z_ea1bPMsg4-cNEnLZP-a2XTwY2Jio7LOqdpCMVvRmnzOWIIVV7kPl4oU0Z2P8HYV9RCQAJq8aXuQYINlgOmGbuPS5NOOYjsU8Db1_qGtt3GwQX_fIK0NLOnSo-N5Vgy2tbgt4lpO5h6N_2xoLkbn0GwFBmNkcD6jbuJrV8DYXdKJcbRB_lRwjYD4QzrWV5_B1c14EpO9ylFYGkNc5XijtRAMX54CcJVg"
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    });



    axiosInstance.interceptors.request.use(async req => {

        return req;
    })

    axiosInstance.interceptors.response.use(res => {

        return res;

    },
        async (error) => {

            //display error message

        })






    return axiosInstance;
}
export default useAxios