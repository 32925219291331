import config from '../bot/config.js';
import './BotComponent.css';
import Chatbot from 'react-chatbot-kit';
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import 'react-chatbot-kit/build/main.css'
import { useContext, useEffect, useState } from 'react';
import DataContext from '../../context/DataContext.js';


const BotComponent = () => {


  const { disableUserChatSendBtn } = useContext(DataContext);




  useEffect(() => {
    const inputElement = getInputChat();
    inputElement.addEventListener('input', handleInputChange);
    inputElement.addEventListener('keydown', handleKeyPress);
    disableSendButton();
  }, [])

  useEffect(() => {

    const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const inputElement = getInputChat();
    if (sendButton) {
      if (disableUserChatSendBtn) {
        disableSendButton()
      }
      else if (inputElement.value === "") {
        disableSendButton()
      }
      else {
        enableSendButton();

      }
    }
  }, [disableUserChatSendBtn])

  const handleKeyPress = (event) => {
    let sendButton = getSendButton();
    if (event.key === 'Enter' && sendButton.classList.contains('disabled')) {
      event.preventDefault(); // Optional: Prevents default behavior of Enter key (like form submission)
    }
  }

  const handleInputChange = (event) => {
    // Get the current value of the input field
    const inputValue = event.target.value;
    if (inputValue.length > 0 && !disableUserChatSendBtn) {
      enableSendButton();
    }
    else (
      disableSendButton()
    )
  }

  const disableSendButton = () => {
    let sendButton = getSendButton()

    if (sendButton) {

      sendButton.classList.add('disabled');


    }
  }

  const enableSendButton = () => {
    let sendButton = getSendButton()

    if (sendButton) {

      sendButton.classList.remove('disabled');

    }
  }


  const getSendButton = () => {
    return document.querySelector('.react-chatbot-kit-chat-btn-send');
  }

  const getInputChat = () => {
    return document.querySelector('.react-chatbot-kit-chat-input');
  }





  return (
    <div>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
};

export default BotComponent